<template>
  <section class="section is-medium"
           :class="pageData.fields.background"
           :style="backgroundImageStyle">
    <div class="container has-text-centered">
      <h1 class="title has-text-weight-light"
          :class="pageData.fields.order === 1?'is-size-1':'is-size-2'">
        {{ pageData.fields.title }}
      </h1>
      <h2 class="subtitle is-size-4">
        {{ pageData.fields.subtitle }}
      </h2>
    </div>
    <div class="hero-body">
      <div class="container has-space-below">
        <rich-text :document="pageData.fields.bodytext"></rich-text>
      </div>
      <!--
              <div class="container is-16by9 video">
                  <video :poster="posterImageURL" preload="metadata" controls @play="logga">
                    <source :src="videoWebm" type="video/webm">
                    <source :src="mp4URL" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
              </div>
      -->

      <div class="container">
        <div class="limitedwidth">
          <div class="video ">
            <iframe :src="youttubeURL(pageData.fields.youtubeId)"
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen/>
            <!--        https://www.youtube-nocookie.com/embed/y2bTiuEVaWw"-->
          </div>
        </div>
      </div>

    </div>
    <div class="hero-footer has-text-centered" v-if="showDownArrow">
      <font-awesome-icon :icon="['fal', 'angle-down']" :class="arrowColor"/>
    </div>
  </section>
</template>

<script>
import PanelGroup from '@/components/PanelGroup.vue'
import RichText from '@/components/RichText.vue'
// import misc from '@/mixins/misc'
//import {documentToHtmlString} from '@contentful/rich-text-html-renderer';

export default {
  name: 'BannerPane',
  // mixins: [misc],
  props: {
    pageData: Object
  },
  components: {
    PanelGroup,
    RichText
  },
  computed: {
    // bodytextHtml() {
    //   return this.convertToHTML(this.pageData.fields.bodytext);
    // },

    arrowColor() {
      let background = this.pageData.fields.background;
      return background === 'has-background-white' ? 'has-text-grey fa-4x' : 'has-text-white fa-4x';
    },

    showDownArrow() {
      return (this.pageData.fields.backgroundImage && this.pageData.fields.order === 1);
    },

    backgroundImageStyle() {
      let backgroundImage = this.pageData.fields.backgroundImage;
      if (backgroundImage) {
        return { //Vue can handle objects
          'background-image': `linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.5) 30%,
            rgba(0,0,0,0.8) 80%,rgba(0,0,0,0.9) 100%),url(${backgroundImage.fields.file.url})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }
      }
      else {
        return ""; //no background image at all
      }
    },

    posterImageURL() {
      let f = this.pageData.fields;
      if (f.videoPosterFrame) {
        f = f.videoPosterFrame.fields.file.url;
        f += "?fit=pad&w=640&h=360"; // resize to HD ration, 960px wide
      }
      else {
        f = "../assets/images/missing.jpg "
      }
      this.$log.debug("{VideoPane} f:", f);
      //this.$log.debug("{VideoPane} this.pageData.fields:", this.pageData.fields);
      return f;
    },

    videoWebm() {
      let f = this.pageData.fields;
      if (f.videoWebm) {
        f = f.videoWebm.fields.file.url;
      }
      else {
        f = "../assets/images/missing.jpg "
      }
      this.$log.debug("{VideoPane} f:", f);
      //this.$log.debug("{VideoPane} this.pageData.fields:", this.pageData.fields);
      return f;
    },

    mp4URL() {
      let f = this.pageData.fields;
      if (f.videoMp4) {
        f = f.videoMp4.fields.file.url;
      }
      else {
        f = "../assets/images/missing.jpg "
      }
      this.$log.debug("{VideoPane} f:", f);
      //this.$log.debug("{VideoPane} this.pageData.fields:", this.pageData.fields);
      return f;
    },


  },
  methods: {
    logga() {
      let vidURL = this.pageData.fields.videoWebm.fields.file.url;
      this.$ga.event('video', 'play', vidURL);
    },

    youttubeURL(id) {
      let stem = "https://www.youtube-nocookie.com/embed/"
      // rel=0 just suggest other videos for our channel
      return stem + id + "?rel=0&modestbranding=1";
    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
@import "@/scss/_bulmaOverridden";

.hero-footer {
  padding-top: 4em;
}

// limit with and position in centre of container
.limitedwidth {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

// wrapper positions iframe wrt aspect ratio using padding then absolutely positions iframe
.video {
  position: relative;
  padding-bottom: 56.25%; //assume 16:9 video ratios
  height: 0;
  overflow: hidden;
}

.video iframe,  {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<!--
.imagesection {
height: auto;
background: linear-gradient(to bottom,#0003 0,#00000080 30%,#000c 80%,#000000e6 100%);
}-->
